import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import Image1 from '../images/etc/happy-haus-currumbin-8.jpg'

const ogImages = [`https://happyhaus.com.au${Image1}`]

const LandscapeImage =
  'etc/happyhaus-gallery-2024-02-02-photo-james-1100686.jpg'

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Disclaimer"
          description="Happy Haus reserves the right to alter specification, pricing and other product information without prior notice. For information on standard working drawings (including forms/facades), standard inclusions and optional display items please consult the Happy Haus team."
          image={LandscapeImage}
        />

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage} ratio={1 / 1.5} />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Title align="left">Disclaimer</Title>
              <Copy>
                <p>
                  Happy Haus reserves the right to alter specification, pricing
                  and other product information without prior notice. For
                  information on standard working drawings (including
                  forms/facades), standard inclusions and optional display items
                  please consult the Happy Haus team. Photography and
                  Illustrations on this website should be used as a guide only
                  and Happy Haus does not supply the following items including
                  landscaping, window furnishings, wallpapers, decorative
                  lighting, décor and furniture. Lot width recommendation
                  subject to land restrictions and may vary between councils.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
